// fonts
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

/* general */
:root {
  --default-padding: 70px 0;
  --font: "Montserrat", sans-serif;
  --blue: rgba(10, 129, 254, 1);
  --lightBlue: #9cdbfc;
  --gray: rgba(164, 164, 164, 1);
  --gray2: rgb(51 51 51);
  --white: #ffffff;
  --black: #000000;
  --lightGray: #f0f0f0;
}

body {
  background-color: var(--white);
  color: var(--white);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  font-family: var(--font);
  font-weight: 400;
  font-display: swap;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 1px;
}

.uk-h1,
.uk-h2,
.uk-h3,
.uk-h4,
.uk-h5,
.uk-h6,
.uk-heading-2xlarge,
.uk-heading-3xlarge,
.uk-heading-large,
.uk-heading-medium,
.uk-heading-small,
.uk-heading-xlarge,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  margin-top: 0;
}
b {
  font-weight: 600 !important;
}
p {
  margin: 0 0 1.3rem;
}
img {
  max-width: 100%;
}
a {
  color: var(--blue);
  &:hover {
    color: var(--white);
  }
}
button.MuiButton-contained {
  background-color: var(--lightBlue);
  color: var(--black);
  text-transform: none;
  &.arrow {
    &::after {
      content: "";
      background-image: url("../images/icons/buttonArrow.png");
      height: 20px;
      width: 45px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right center;
    }
  }
  &:hover {
    background-color: var(--lightBlue);
    opacity: 0.9;
  }
}

/* utilities */
section {
  padding: var(--default-padding);
  &#Home,
  &.innerBannerContainer {
    padding: 0;
  }
}
.bgBlue {
  background-image: linear-gradient(117deg, #051017 0%, #0c2331 100%);
  position: relative;
  z-index: 2;
  padding: var(--default-padding);
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  .imageRight {
    background-image: url("../images/plusRight.png");
    background-size: cover;
    width: 150px;
    height: 150px;
    position: absolute;
    right: 0;
    top: 0;
    &.left {
      right: auto;
      left: 0;
    }
  }
  .mainTitle h2 {
    color: var(--white);
  }
}
.bgBlack {
  padding: var(--default-padding);
  background-color: var(--black);
  // min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  position: relative;
  max-width: 100%;
  .imageRight {
    background-image: url("../images/plusRight.png");
    background-size: cover;
    width: 150px;
    height: 150px;
    position: absolute;
    right: 0;
    top: 0;
    &.left {
      right: auto;
      left: 0;
    }
  }

  .mainTitle {
    padding-right: 190px;
  }
  * {
    color: var(--white);
  }
}
.bgGray {
  background-color: var(--lightGray);
}

.bgWhite {
  background-color: #ffffff;
}
/* title */
.bgBlack {
  .mainTitle {
    h3,
    h2,
    p {
      color: var(--white);
    }
  }
}
.mainTitle {
  margin-bottom: 3rem;
  h3 {
    margin-bottom: 0;
    // background: linear-gradient(
    //   to right,
    //   rgba(10, 129, 254, 1),
    //   rgb(19, 118, 224),
    //   rgba(48, 187, 251, 1),
    //   rgba(139, 208, 252, 1)
    // );
    // -webkit-text-fill-color: transparent;
    // background-clip: text;
    // -webkit-background-clip: text;
    display: inline-block;
    color: var(--black);
    font-size: 2.3rem;
    font-weight: 300;
  }
  h2 {
    font-size: 2.3rem;
    color: var(--black);
    margin-top: 0.3rem;
  }
  p {
    font-size: 1.1rem;
  }
  &.titleCenter {
    text-align: center;
  }
}
* {
  box-sizing: border-box;
}

// header
header.headerInner {
  background-color: var(--black);
  padding: 1.1rem 0;
  .MuiToolbar-root {
    width: 100%;
    padding: 0;
  }
  .logo {
    height: 50px;
    position: static;
  }
  .menuItems {
    margin-left: auto;
    display: flex;
    align-items: center;
    a {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      color: var(--white);
      text-decoration: none;
      font-weight: 600;
      &:hover {
        color: var(--lightBlue);
      }
      &.active {
        color: var(--lightBlue);
      }
    }
  }
}

// main banner
.ukMainSlider {
  height: 100vh;
  overflow: hidden;
  .videoOverlay {
    // background-image: radial-gradient(
    //   circle at bottom center,
    //   rgba(0, 0, 0, 0) 17%,
    //   rgba(0, 0, 0, 0.7) 80%
    // );
    background: linear-gradient(
      to top right,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.1)
    );
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
  .ukSlideContent {
    z-index: 5;
    top: 39%;
    margin: 0;
    padding: 2rem;
    h2,
    p {
      color: var(--white);
      font-weight: 500;
    }
    h2 {
      font-size: 2.1rem;
    }
    p {
      font-size: 1.5rem;
    }
  }
  .sliderControls {
    position: absolute;
    z-index: 5;
    bottom: 5vh;
    max-width: 100%;
    left: 10%;
    padding-left: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    button {
      color: #fff;
      margin-left: 2rem;
      margin-right: 2rem;
    }
    .uk-position-small {
      font-size: 2.5rem;
      position: static !important;
      margin: 0;
      transform: none;
      line-height: 1;
    }
  }
  .uk-position-medium {
    width: 40%;
    max-width: 100%;
    left: 10%;
    bottom: 6rem;
    top: auto;
  }
  .uk-slideshow-items {
    height: 100%;
    video {
      object-fit: cover;
    }
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
  .bgEvent {
    background-image: linear-gradient(
      to right,
      rgba(139, 208, 252, 1),
      rgba(10, 129, 254, 1)
    );
    display: inline-block;
    color: #0f0f0f;
    padding: 0.1rem 0.6rem;
    text-transform: uppercase;
    margin-bottom: 0.9rem;
  }
  .learnMore {
    color: var(--blue);
    font-size: 1.1rem;
  }
  .slidingPanel {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    right: 0;
    display: flex;

    .textClick {
      width: 30px;
      height: 193px;
      object-fit: cover;
      position: absolute;
      left: -30px;
      top: 0;
      cursor: pointer;
      display: block;
    }
    &.active {
      .content {
        right: 0;
      }
    }
    .content {
      position: relative;
      padding: 1rem;
      color: var(--white);
      right: -100%;
      background-color: var(--black);
      transition: all ease 0.5s;
      height: 193px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      h3 {
        background-image: linear-gradient(
          to right,
          rgba(10, 129, 254, 1),
          rgb(19, 118, 224),
          rgba(48, 187, 251, 1),
          rgba(139, 208, 252, 1)
        );
        display: inline-block;
        font-size: 0.9rem;
        padding: 0.1rem 0.3rem;
        border-radius: 3px;
        margin-bottom: 0.7rem;
      }
      h4 {
        font-size: 1.2rem;
        margin-bottom: 0.1rem;
        b {
          font-weight: 700;
        }
      }
      p {
        font-weight: 600;
        margin-bottom: 0.5rem;
      }
      a {
        font-size: 0.9rem;
        color: var(--blue);
        font-weight: 600;
      }
    }
    // h3 {
    //   transform-origin: 0 0;
    //   transform: rotate(0deg);
    //   white-space: nowrap;
    //   background-color: var(--blue);
    // }
  }
  .uk-slideshow-items {
    > div:first-child {
      .ukSlideContent {
        width: 80%;
      }
    }
  }
}

.innerBannerContainer {
  .imageBanner {
    padding: 11rem 0 5rem;
    color: var(--white);
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    h5,
    h1 {
      color: var(--white);
      font-size: 2.7rem;
    }
    h5 {
      font-weight: 300;
      margin-bottom: 0.5rem;
    }
    p {
      margin-top: 1.5rem;
      margin-bottom: 2rem;
    }
  }
}

// inner Banner
.innerBanner {
  position: relative;
  video {
    object-fit: cover;
  }
  .overlay {
    background-image: linear-gradient(
      to right,
      rgba(1, 10, 15, 0.9),
      rgba(6, 36, 52, 0.3)
    );
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    width: 1200px;
    color: var(--white);
    .mainTitle {
      width: 50%;
      margin-bottom: 0;
      h3 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }
      h2 {
        font-size: 2.7rem;
        b {
          display: block;
          letter-spacing: 2px;
        }
      }
    }
  }
}

// Accordion tab
.accordionPanel {
  .MuiAccordionDetails-root img {
    display: none;
  }
}

// flip box
.service-box {
  position: relative;
  overflow: hidden;
  perspective: 1000px;
  -webkit-perspective: 1000px;
  margin-bottom: 2.5%;
  .service-icon {
    border: 1px solid var(--gray2);
  }
  .service-icon,
  .service-content {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 320px;
    padding: 1rem;
    transition: all 0.5s ease;
  }
  .service-content {
    justify-content: center;
  }
  &.bgImage .service-icon::before {
    background-color: transparent;
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 9%,
      rgba(0, 57, 69, 0.1) 50%,
      rgba(0, 0, 0, 0.9) 80%,
      rgb(0, 0, 0) 100%
    );
  }
  .service-icon {
    align-items: flex-end;
    background-size: cover;
    background-position: center center;
    position: relative;
    &::before {
      content: "";
      display: block;
      background-color: rgb(0, 0, 0, 70%);
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      left: 0;
    }
    h3 {
      font-size: 1.3rem;
      color: #fff;
      margin-bottom: 0;
      position: relative;
    }
    > svg {
      position: absolute;
      bottom: 0.7rem;
      right: 0.7rem;
      width: 22px;
      display: none;
    }
    &.iconsFront {
      flex-direction: column;
      align-items: center;
      img {
        height: 70px;
      }
    }
  }
  .service-content {
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    background-color: var(--gray2);
    color: var(--white);
    backface-visibility: hidden;
    transform-style: preserve-3d;
    -webkit-transform: translateY(110px) rotateX(-90deg);
    -moz-transform: translateY(110px) rotateX(-90deg);
    -ms-transform: translateY(110px) rotateX(-90deg);
    -o-transform: translateY(110px) rotateX(-90deg);
    transform: translateY(110px) rotateX(-90deg);
    img {
      height: 65px;
      margin-bottom: 1.5rem;
    }
    svg {
      height: 65px;
      margin-bottom: 1.5rem;
      path {
        fill: var(--blue);
      }
    }
    h3 {
      font-size: 1.3rem;
      margin-bottom: 10px;
      color: var(--white);
      margin-bottom: 1.5rem;
    }
    a {
      margin-top: 1rem;
      color: #ffffff;
      background-color: var(--gray);
      font-size: 0.8rem;
      padding: 0.1rem 1rem;
      border-radius: 1.5rem;
      &:hover {
        background-color: var(--gray);
        color: var(--blue);
      }
    }
  }
  &:hover {
    .service-icon {
      opacity: 0;
      -webkit-transform: translateY(-110px) rotateX(90deg);
      -moz-transform: translateY(-110px) rotateX(90deg);
      -ms-transform: translateY(-110px) rotateX(90deg);
      -o-transform: translateY(-110px) rotateX(90deg);
      transform: translateY(-110px) rotateX(90deg);
    }
    .service-content {
      opacity: 1;
      -webkit-transform: rotateX(0);
      -moz-transform: rotateX(0);
      -ms-transform: rotateX(0);
      -o-transform: rotateX(0);
      transform: rotateX(0);
    }
  }
}

// hover show content
.hoverContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .hoverContent {
    margin-bottom: 1%;
    width: 49.5%;
    &:first-child {
      width: 55%;
    }
    &:nth-child(2) {
      width: 44%;
    }
  }
}
.hoverContent {
  background-size: cover;
  position: relative;
  padding: 1rem;
  height: 410px;
  display: flex;
  align-items: flex-end;
  border-radius: 15px;
  overflow: hidden;
  border: 1px solid var(--gray2);
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(
      35deg,
      rgb(0 0 0),
      rgb(0 0 0 / 90%),
      rgb(51 51 51 / 50%)
    );
    opacity: 0.9;
    transition: opacity 0.5s ease-in-out;
  }
  &:hover {
    &::before {
      background-image: linear-gradient(
        35deg,
        rgb(0 0 0),
        rgb(0 0 0 / 90%),
        rgb(51 51 51 / 90%)
      );
      opacity: 1;
    }
    .content p {
      height: 170px;
    }
  }
  .content {
    position: relative;
    overflow: hidden;
    span {
      font-size: 1.3rem;
      text-align: right;
      display: block;
      color: var(--white);
      font-weight: 600;
    }
    h4 {
      color: var(--blue);
      font-size: 3.3rem;
      text-align: right;
    }
    p {
      text-align: right;
      height: 0;
      margin-bottom: 0;
      transition: all ease 0.5s;
    }
  }
}
.iconHoverBox {
  text-align: center;
  margin: 0.51rem;
  position: relative;
  z-index: 1;
  &::before {
    content: url("../images/benefitsHover.png");
    width: 150px;
    position: absolute;
    bottom: -24px;
    left: -24px;
    transition: all ease 0.5s;
    opacity: 0;
  }
  .content {
    position: relative;
    z-index: 1;
    background-color: var(--white);
    height: 390px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    transition: all ease 0.5s;
    overflow: hidden;
    border-radius: 0.5rem;
    box-shadow: 0 0 25px 3px #00000014;
  }
  &:hover {
    z-index: 2;
    &::before {
      opacity: 1;
    }
    img,
    svg {
      height: 0;
      opacity: 0;
      margin-bottom: 0;
    }
    p {
      height: 191px;
      opacity: 1;
    }
  }
  img {
    height: 55px;
    margin-bottom: 1.3rem;
    opacity: 1;
    transition: all ease 0.5s;
  }
  h4 {
    font-size: 1.2rem;
    margin-bottom: 0.3rem;
  }
  h2 {
    color: var(--blue);
    font-size: 2.7rem;
  }
  p {
    margin-bottom: 0;
    font-size: 0.9rem;
    height: 0;
    opacity: 0;
    transition: all ease 0.5s;
  }
  svg {
    font-size: 2rem;
    color: var(--gray);
    transition: all ease 0.5s;
  }
}

// tabs
.muiTabs {
  min-height: 410px;
  flex-grow: 1;
  display: flex;
  background-image: url("../images/bgCloud.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 15rem 5.5rem 5rem;
  .tabNav {
    width: 27%;
    margin: auto;
    button {
      white-space: nowrap;
      color: #ffffff;
      text-transform: none;
      font-size: 1.1rem;
      font-weight: 600;
      letter-spacing: 1px;
      background-image: linear-gradient(
        45deg,
        var(--blue),
        rgb(91 90 193),
        rgb(91 90 193)
      );
      margin-bottom: 9px;
      border-radius: 35px 0 0 35px;
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      img {
        height: 21px;
        margin-right: 0.5rem;
      }
      svg {
        width: 0;
        margin-left: 0.5rem;
        transition: all ease 0.5s;
        height: 25px;
      }
      &:hover {
        svg {
          width: 25px;
        }
      }
    }
    .MuiTabs-indicator {
      background-color: var(--blue);
      width: 3px;
    }
  }
  .tabPanels {
    padding: 0 1rem;
    width: 70%;
    overflow: hidden;
    margin: auto;
    > .MuiBox-root {
      display: flex;
      align-items: center;
    }
    .tabsContent {
      padding: 1rem 2rem 1rem 1rem;
    }
    h5 {
      font-weight: 600;
      margin-bottom: 1rem;
      color: var(--black);
    }
    img {
      width: 45%;
      height: 253px;
    }
    p {
      color: var(--black);
      // font-weight: 600;
    }
  }
}

// Platform Features
.imgBlock {
  .blockTitle {
    display: flex;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 2.5rem;
    img {
      height: 31px;
      margin-right: 0.5rem;
    }
    h3 {
      margin-bottom: 0;
      font-size: 1.2rem;
    }
  }
  p {
    font-size: 1.4rem;
    margin-bottom: 2.5rem;
  }
  ul {
    padding-left: 0;
    font-size: 1rem;
    list-style-position: inside;
    letter-spacing: 1px;
  }
}

// use case
.image-container {
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease;
    transform: scale(0.91);
    &.zoom-in {
      transform: scale(1);
      transition: transform 0.9s ease;
      /* Zoom-in effect */
    }
  }
}

// Insights
.slickInsights {
  .slick-prev:before,
  .slick-next:before {
    display: none;
  }
  .slick-prev,
  .slick-next {
    svg {
      width: 50px;
      height: 50px;
      polyline {
        stroke: var(--white);
      }
      &:hover {
        polyline {
          stroke: var(--blue);
        }
      }
    }
  }
  .slick-slide {
    opacity: 0.5;
    transform: scale(0.9);
    transition: all 0.7s;
  }
  .slick-center {
    opacity: 1;
    transform: scale(1);
  }
  .slick-prev {
    left: -45px;
  }
  .slick-next {
    right: -45px;
  }
}
.insights {
  position: relative;
  border-radius: 5px;
  border: 1px solid var(--gray2);
  overflow: hidden;
  .content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 3rem 0.7rem 1rem;
    background-image: linear-gradient(
      0deg,
      rgb(0 0 0),
      rgb(51 51 51 / 90%),
      rgb(51 51 51 / 30%),
      rgb(51 51 51 / 10%)
    );
    &::before {
      content: "";
      position: absolute;
      background-image: linear-gradient(
        to bottom,
        rgb(0 0 0 / 1%),
        rgb(0 0 0 / 60%),
        rgb(0 0 0 / 80%),
        rgb(0 0 0 / 90%)
      );
      bottom: 0;
      width: 100%;
      height: 100%;
      left: 0;
    }
  }
  .title {
    display: flex;
    justify-content: space-between;
    position: relative;
    img {
      height: 31px;
      margin-bottom: 1rem;
    }
    h4 {
      font-size: 1.1rem;
      color: #ffffff;
    }
  }
  p {
    font-size: 0.8rem;
    position: relative;
    margin-bottom: 0.7rem;
    color: var(--white);
  }
  button {
    font-size: 0.8rem;
    display: block;
    padding: 0;
  }
}
.drawerInsight {
  .MuiDialog-paperScrollPaper {
    background-color: var(--black);
    color: var(--white);
    padding: 2rem 6rem;
    max-width: 1000px;
    box-shadow: 0 0 50px 15px rgb(10 129 254 / 20%);
  }
  .MuiBackdrop-root {
    background-image: radial-gradient(
      rgb(0 0 0 / 70%),
      rgb(0 0 0 / 70%),
      rgb(0 0 0 / 100%)
    );
  }
  h2 {
    color: var(--white);
    font-size: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      height: 35px;
      margin-left: 1rem;
    }
  }
  h5 {
    font-size: 1.3rem;
    color: var(--white);
  }
  p {
    color: var(--gray);
    margin-bottom: 1rem;
  }
}

// index
.cloudCapabilities {
  .mainTitle {
    margin-bottom: 0;
    p {
      width: 53%;
    }
  }
  .muiTabs {
    margin-top: -7rem;
  }
  .platformCloud {
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    padding: 14rem 3rem 7rem 5rem;
    margin-top: -5rem;
    h4,
    p {
      color: var(--black);
    }
    h4 {
      font-weight: 700;
      margin-bottom: 1.3rem;
    }
    p,
    a {
      font-weight: 600;
    }
    a {
      color: var(--blue);
      margin-top: 1rem;
      display: block;
      img {
        margin-left: 0.5rem;
      }
    }
    .content {
      h4 img {
        display: none;
      }
      padding: 1.5rem;
    }
    .graphic {
      width: 230px;
    }
    .platform {
      width: 310px;
    }
  }
}

// vollee in action
// Core Principles
.subTitleBlock {
  > div {
    margin-bottom: 9rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  + .subTitleBlock {
    > div {
      margin-top: 8rem;
    }
  }
  .imgBlockScroll {
    overflow: hidden;
    margin-bottom: 3rem;
    height: 330px;
    border-radius: 15px 15px 0 0;
    img {
      scale: 1.5;
      transform: translateY(-10%);
    }
  }
  h4 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: var(--white);
  }
  .emptyBlock {
    height: 30vh;
  }
  .imageScrollSection {
    background-color: var(--white);
    border-radius: 15px;
    overflow: hidden;
    padding: 0.7rem;
    padding-bottom: 2rem;
    h4,
    p {
      color: var(--black);
      padding: 0 2.5rem;
    }
    h4 {
      font-size: 2rem;
      font-weight: 700;
    }
    p {
      font-weight: 500;
      font-size: 1.1rem;
    }
  }
}

// key Benefits
.slickKeyBenefits {
  margin: 0 9rem;
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      color: var(--white);
      font-size: 2.3rem;
      line-height: 1.2;
      margin-bottom: 0;
    }
    p {
      color: var(--blue);
      font-size: 5rem;
      font-weight: 600;
      margin-bottom: 0;
    }
  }
  .slick-prev,
  .slick-next {
    font-size: 2rem;
    line-height: 1;
    width: 70px;
    height: 70px;
    color: var(--gray);
    svg {
      width: 50px;
      height: 50px;
    }
    polyline {
      stroke: var(--gray);
    }
    &:hover {
      polyline {
        stroke: var(--blue);
      }
    }
    &::before {
      display: none;
    }
  }
  .slick-next {
    right: -99px;
  }
  .slick-prev {
    left: -99px;
  }
}

// customers banner
.bannerCustomer {
  position: relative;
  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   left: 0;
  //   bottom: 0;
  //   background-image: linear-gradient(
  //     92deg,
  //     rgb(6 4 4 / 70%),
  //     rgb(63 52 52 / 10%)
  //   );
  // }
  .imageBanner {
    width: 100%;
    height: 70vh;
    object-fit: cover;
  }
  h1 {
    position: absolute;
    width: 1154px;
    max-width: 100%;
    top: 2rem;
    left: 50%;
    transform: translateX(-50%);
    color: var(--white);
    z-index: 2;
  }
  .logoList {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: var(--black);
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    img {
      height: 80px;
      padding: 1.5rem;
      object-fit: contain;
      &.active {
        background-color: var(--blue);
      }
    }
  }
}
.customerContent {
  text-align: center;
  padding-top: 5rem;
  img {
    height: 55px;
    margin-bottom: 2rem;
  }
  h4 {
    font-weight: 600;
    font-size: 1.7rem;
    margin-bottom: 3rem;
  }
  p {
    margin-bottom: 1.5rem;
    &:last-child {
      margin-bottom: 0;
    }
    i {
      letter-spacing: 2px;
      font-size: 1rem;
    }
  }
  .quotes {
    font-size: 5rem;
    line-height: 0;
    display: flex;
    justify-content: center;
    margin-top: 4.5rem;
  }
  .quotesBy {
    &::before {
      content: "";
      width: 110px;
      height: 1px;
      background-color: var(--white);
      display: block;
      margin: auto;
      margin-bottom: 1rem;
    }
    font-size: 0.9rem;
    font-weight: 400;
  }
  .vollee {
    color: #9cdbfc;
    font-weight: 600;
  }
}

// useCase
.useCaseImage {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  &:hover {
    // img {
    //   filter: blur(5px);
    //   -webkit-filter: blur(5px);
    // }
    .imageBlock {
      height: 100%;
      padding-bottom: 5rem;
    }
    a {
      height: 37px;
      opacity: 1;
    }
  }
  img {
    transition: all 0.3s;
  }
  h3 {
    color: var(--lightBlue);
    font-size: 2rem;
    font-weight: 700;
    position: relative;
    line-height: 1.2;
    margin-bottom: 0.5rem;
  }
  p {
    position: relative;
    color: var(--white);
    font-size: 0.9rem;
    margin-bottom: 0.7rem;
    font-weight: 600;
  }
  a {
    position: relative;
    height: 0;
    opacity: 0;
    transition: all 0.3s;
  }
  .imageBlock {
    position: absolute;
    bottom: 0;
    padding: 2rem;
    transition: all 0.5s;
    height: 250px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    &::before {
      content: "";
      display: block;
      background-image: linear-gradient(
        to bottom,
        rgb(0 0 0 / 1%),
        rgb(0 0 0 / 30%),
        rgb(0 0 0 / 60%),
        rgb(0 0 0 / 70%)
      );
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      left: 0;
    }
  }
  button.arrow:after {
    height: 12px;
    width: 31px;
  }
}

.aiBlock {
  h3 {
    font-size: 1.5rem;
    margin-bottom: 0;
  }
  p {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
.aiVideo {
  background-image: linear-gradient(
    315deg,
    rgb(12, 40, 57) 0%,
    rgb(18, 59, 85) 100%
  );
  video {
    object-fit: cover;
  }
}
.policyTerm {
  padding-top: 5rem;
  padding-bottom: 3rem;
  h1 {
    font-size: 5rem;
  }
  h3 {
    font-size: 2rem;
    margin-top: 3rem;
    margin-bottom: 0.5rem;
  }
}

// productivity
.productivityBody {
  background-image: linear-gradient(145deg, #051017 0%, #0c2331 100%);
  .MuiGrid-item {
    position: relative;
  }
  .borderedLeft {
    position: absolute;
    width: 2px;
    left: 0;
    top: 0;
    height: 100%;
    background-image: linear-gradient(90deg, #6c21b7 0%, #3fa1b9 100%);
  }
  .content {
    padding: 4rem 5rem 6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--white);
    &:nth-child(3) {
      .left span {
        background-image: linear-gradient(90deg, #7579de, #5437ac);
      }
    }
    &:nth-child(4) {
      .left span {
        background-image: linear-gradient(90deg, #6c81d8, #3a54bf);
      }
    }
    &:nth-child(5) {
      .left span {
        background-image: linear-gradient(90deg, #6aa3cc, #1267af);
      }
    }
    &:last-child {
      padding-bottom: 9rem;
      .left span {
        background-image: linear-gradient(90deg, #7fb9ce, #2e8ca5);
      }
    }
    .left {
      width: 70%;
      span {
        background-image: linear-gradient(90deg, #756ad5, #6c22b7);
        display: inline-flex;
        align-items: center;
        padding: 1rem 3.5rem 1rem 2rem;
        font-size: 1.3rem;
        font-weight: 500;
        margin-bottom: 2.5rem;
        img {
          height: 29px;
          margin-right: 0.7rem;
        }
      }
      h3 {
        font-size: 2.5rem;
        color: var(--white);
        margin-bottom: 0.8rem;
      }
    }
    .right {
      width: 25%;
    }
  }
}
// customers
.slickCustomers {
  .slick-prev,
  .slick-next {
    display: none !important;
  }
  .slick-current {
    z-index: 5;
  }
}
.slickCustomer {
  background-color: var(--black);
  text-align: center;
  border-bottom: 3px solid var(--lightBlue);
  border-radius: 50px 50px 0 0;
  position: relative;
  margin: 1rem 6rem;
  padding: 4rem;
  .customer {
    position: absolute;
    width: 50px;
    height: 50px;
    top: -15px;
    right: 0;
    object-fit: contain;
    margin-bottom: 0;
  }
  img {
    height: 30px;
    margin: auto;
    margin-bottom: 1.5rem;
  }
  h3 {
    color: #ffffff;
    font-size: 1.7rem;
  }
  a {
    color: var(--lightBlue);
  }
}
.slickCustomerLogo {
  img {
    height: 50px;
    margin-left: 5px;
    margin-right: 5px;
    margin: auto;
    object-fit: contain;
    cursor: pointer;
    padding: 10px;
    border-radius: 10px;
  }
  .slick-current img {
    background-color: var(--black);
  }
}
// contact us
.getInTouch {
  .titleContact {
    text-align: center;
    color: var(--white);
    .coloredText {
      font-size: 2.7rem;
      font-weight: 300;
      margin-bottom: 0.5rem;
    }
    h5 {
      color: var(--white);
      font-weight: 600;
      font-size: 2.5rem;
      margin-bottom: 1rem;
    }
    p {
      font-size: 1.1rem;
      padding: 0 11rem;
      margin-top: 1.5rem;
    }
    button {
      margin-top: 4rem;
      padding: 0.5rem 2rem;
      font-size: 1.3rem;
    }
  }
}
.formDrawer {
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.7);
  }
  .MuiDrawer-paper {
    background-color: #000000;
    color: var(--white);
    left: 2rem;
    right: 2rem;
    padding: 7rem 0;
  }
  h3 {
    color: var(--white);
    font-size: 2.1rem;
    margin-bottom: 2rem;
  }
  // input
  .MuiInputBase-root {
    color: var(--white);
    fieldset {
      border-color: var(--gray);
    }
    &:hover {
      fieldset {
        border-color: var(--white);
      }
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: #fff !important;
    }
    input {
      padding: 21.5px 23px;
    }
  }
  .MuiFormControl-root {
    padding-bottom: 1.5rem;
    margin-bottom: 1em;
    label {
      color: var(--gray);
      line-height: 2.2em;
      &.Mui-focused {
        color: var(--blue);
      }
      &[data-shrink="true"] {
        line-height: 1.7em;
      }
    }
  }
  .MuiFormHelperText-root {
    position: absolute;
    bottom: 0;
    margin-left: 1rem;
  }
  .btnSubmit {
    font-size: 1.3rem;
    padding: 0.7rem;
  }
  .drawerClose {
    color: var(--white);
    position: absolute;
    top: 5rem;
    right: 3rem;
    &:hover {
      color: var(--blue);
    }
    svg {
      font-size: 2.5rem;
    }
  }
}

// inner pages
.innerPage {
  background-image: linear-gradient(151deg, #051017 0%, #0c2331 100%);
  padding-bottom: 5rem;
  color: var(--white);
  h3,
  h4,
  h5 {
    color: var(--white);
  }
  .bannerMain {
    padding: 3rem 0 5rem;
    margin-bottom: 3rem;
    .title {
      margin-bottom: 3rem;
      h5 {
        color: var(--lightBlue);
        font-weight: 600;
        margin-bottom: 0.7rem;
      }
      h4 {
        font-weight: 400;
        font-size: 2.3rem;
      }
    }
    .content {
      position: relative;
      padding: 0 2.5rem;
      &::before {
        content: "";
        width: 2px;
        height: 100%;
        background-image: linear-gradient(90deg, #6c21b7 0%, #3fa1b9 100%);
        position: absolute;
        left: 0;
      }
      h3 {
        font-size: 2.5rem;
        font-weight: 600;
        margin-bottom: 2rem;
      }
      p {
        font-size: 1.1rem;
      }
    }
  }
}
// footer
footer {
  background-color: #000;
  padding: var(--default-padding);
  h4 {
    color: var(--white);
    font-size: 1.3rem;
    margin-bottom: 2rem;
  }
  .globalSupport {
    height: 75px;
  }
  .footerSocial {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
    img {
      width: 41px;
      margin-right: 7px;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  p {
    font-size: 1rem;
    letter-spacing: 1px;
    font-weight: 600;
    color: var(--white);
  }
  .footerLinks {
    list-style: none;
    padding-left: 0;
    font-size: 1rem;
    letter-spacing: 1px;
    margin-bottom: 2rem;
    a {
      color: var(--white);
      opacity: 1;
      text-decoration: none;
      &:hover {
        color: var(--lightBlue);
      }
    }
    li {
      line-height: 1;
      margin-bottom: 1.1rem;
    }
  }
  .copyright {
    margin-top: 3.5rem;
    font-size: 0.9rem;
  }
}

// Menu Toggle
#navIcon {
  width: 51px;
  height: 35px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 50%;
    background: var(--white);
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    &:nth-child(even) {
      left: 50%;
      border-radius: 0 9px 9px 0;
    }
    &:nth-child(odd) {
      left: 0px;
      border-radius: 9px 0 0 9px;
    }
    &:nth-child(1),
    &:nth-child(2) {
      top: 0px;
    }
    &:nth-child(3),
    &:nth-child(4) {
      top: 18px;
    }
    &:nth-child(5),
    &:nth-child(6) {
      top: 36px;
    }
  }
  &.open span {
    &:nth-child(1),
    &:nth-child(6) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    &:nth-child(2),
    &:nth-child(5) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
    &:nth-child(1) {
      left: 5px;
      top: 7px;
    }
    &:nth-child(2) {
      left: calc(50% - 5px);
      top: 7px;
    }
    &:nth-child(3) {
      left: -50%;
      opacity: 0;
    }
    &:nth-child(4) {
      left: 100%;
      opacity: 0;
    }
    &:nth-child(5) {
      left: 5px;
      top: 22px;
    }
    &:nth-child(6) {
      left: calc(50% - 5px);
      top: 22px;
    }
  }
}
header {
  position: fixed;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  width: 100%;
  .logo {
    top: 4vw;
    left: 5vw;
    position: absolute;
    opacity: 1;
    z-index: 9;
    transition: all 1000ms cubic-bezier(0.335, 0.01, 0.03, 1.36);
    img {
      height: 55px;
    }
    &.hide {
      opacity: 0;
      pointer-events: none;
    }
  }
  #navIcon {
    top: 4vw;
    position: absolute;
    right: 5vw;
    z-index: 9;
    opacity: 0.7;
    transition-duration: 0.3s;
    &:hover {
      opacity: 1;
    }
  }
  .menu_overlay {
    width: 100vw;
    height: 100vh;
    position: absolute;
    overflow: hidden;
    color: #fff;
    background: #c94b4b;
    background: -webkit-linear-gradient(to right, #000000, #0f0f0f);
    background: linear-gradient(to right, #000000, #0f0f0f);
    z-index: 8;
    transform: translateX(110%);
    transition: transform 1s ease;
    &.open {
      transform: translateX(0);
    }
    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100vw;
      height: 100vh;
      padding-left: 0;
      li a {
        width: 30vw;
        font-size: 2.3rem;
        border-bottom: 1px solid var(--white);
        padding: 1rem 0;
        opacity: 0.5;
        transition-duration: 1s;
        display: block;
        text-decoration: none;
        color: var(--white);
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

/* locomotive */
/* Only necessary with horizontal scrolling */
html[data-scroll-orientation="horizontal"] {
  body {
    width: fit-content;
  }
  [data-scroll-container] {
    display: flex;
  }
}

/* mui */
div.MuiAccordion-root {
  background-color: transparent;
  color: var(--gray);
  border: none;
  box-shadow: none;
  padding: 0.5rem 0;
  &::before {
    display: none;
  }
  &.Mui-expanded {
    margin-bottom: 0;
    padding: 0;
    .MuiSvgIcon-root {
      color: var(--blue);
    }
    .MuiAccordionSummary-content {
      color: var(--white);
    }
  }
  &:last-of-type {
    border-radius: 0;
  }
  .MuiAccordionDetails-root {
    padding-left: 26px;
  }
  .MuiSvgIcon-root {
    color: var(--white);
  }
  .MuiAccordionSummary-gutters {
    padding: 0;
    justify-content: flex-start;
    min-height: unset;
    margin-bottom: 1rem;
  }
  .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
    flex-grow: unset;
    font-size: 1.3rem;
    margin: 0;
    img {
      height: 15px;
      margin-right: 0.7rem;
    }
  }
  .MuiAccordionSummary-expandIconWrapper {
    margin-left: 1rem;
    svg {
      font-size: 1.3rem;
    }
  }
}

// responsive
@media (min-width: 1200px) {
  .cloudCapabilities .platformCloud .content {
    background-image: none;
  }
}
@media (max-width: 1199px) {
  .ukMainSlider {
    .uk-position-medium {
      width: 100%;
      left: 0;
    }
    .sliderControls {
      left: 0;
      bottom: 2rem;
    }
  }
  .bgBlack .mainTitle {
    padding-right: 0;
  }
  .cloudCapabilities {
    .mainTitle p {
      width: 100%;
    }
    .platformCloud {
      margin-top: 3rem;
      padding: 0;
      background-image: none;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      .platform,
      .graphic {
        // display: none;
        width: 50%;
        order: 2;
      }
      .graphic {
        width: 30%;
        margin-left: 10%;
      }
      .content {
        background-size: 100% auto;
        padding: 11rem 2.5rem 5.5rem 3rem;
        background-repeat: no-repeat;
        margin: 3rem 0;
        order: 1;
      }
    }
  }
  .iconHoverBox {
    p {
      height: auto;
      opacity: 1;
    }
    .content {
      height: auto;
    }
    svg {
      display: none;
    }
  }
  .slickCustomer {
    margin: 1rem 0;
  }
  .getInTouch .titleContact p {
    padding: 0;
  }
  header.headerInner .menuItems {
    a {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
  .innerBannerContainer .imageBanner {
    padding: 5rem 0 5rem;
    h5 {
      font-size: 1.7rem;
    }
    h1 {
      font-size: 1.9rem;
    }
  }
  .innerPage .bannerMain {
    .content {
      padding-left: 0;
      &::before {
        display: none;
      }
      h3 {
        font-size: 1.7rem;
      }
      p {
        font-size: 1rem;
      }
    }
    .title {
      margin-bottom: 2rem;
      h5 {
        font-size: 1.3rem;
      }
      h4 {
        font-size: 1.6rem;
      }
    }
  }
  .subTitleBlock {
    .imgBlockScroll {
      height: 250px;
      margin-bottom: 2rem;
    }
    .imageScrollSection {
      h4 {
        font-size: 1.5rem;
      }
      p {
        font-size: 1rem;
      }
    }
  }
}
@media (max-width: 899px) {
  body {
    font-size: 1rem;
  }
  header {
    .logo {
      top: 3.5vw;
      img {
        height: 30px;
      }
    }
    #navIcon {
      top: 5vw;
      right: 5vw;
      width: 31px;
      height: 23px;
      span {
        height: 1px;
        &:nth-child(3),
        &:nth-child(4) {
          top: 9px;
        }
        &:nth-child(5),
        &:nth-child(6) {
          top: 18px;
        }
      }
      &.open span {
        &:nth-child(1) {
          left: 3px;
          top: 7px;
        }
        &:nth-child(2) {
          left: calc(50% - 1px);
          top: 7px;
        }
        &:nth-child(5) {
          left: 3px;
          top: 19px;
        }
        &:nth-child(6) {
          left: calc(50% - 2px);
          top: 18px;
        }
      }
    }
    .menu_overlay ul li a {
      width: 75vw;
      font-size: 1.5rem;
    }
    &.headerInner {
      padding: 0;
      .MuiToolbar-root {
        display: flex;
        justify-content: space-between;
        .menuItems {
          display: none;
        }
        .logo {
          height: 35px;
        }
      }
    }
  }
  .menuDrawer {
    > .MuiPaper-root {
      background-color: var(--black);
    }
    .MuiIconButton-root {
      margin-left: auto;
      padding-right: 2rem;
      padding-top: 2rem;
      svg {
        color: var(--white);
        fill: var(--white);
      }
    }
    .itemList {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .MuiButtonBase-root {
        justify-content: center;
        font-size: 1.5rem;
        a {
          color: var(--white);
        }
      }
    }
  }
  .cloudCapabilities .platformCloud {
    .content {
      padding: 6.5rem 2.5rem 4.5rem 3rem;
      // margin: 0;
      // background-image: none;
    }
    // .platform,
    // .graphic {
    //   width: 80%;
    // }
    // h4,
    // p {
    //   color: var(--white);
    // }
    h4 {
      font-size: 2vw;
    }
    p,
    a {
      font-size: 1.1vw;
    }
  }
  .useCaseImage {
    .imageBlock {
      height: 100%;
    }
    h3 {
      font-size: 1.5rem;
    }
    a {
      height: auto;
      opacity: 1;
    }
  }
  .slickCustomer {
    padding: 1rem;
    h3 {
      font-size: 1.3rem;
    }
  }
  .slickCustomerLogo {
    .slick-slide {
      width: 25% !important;
    }
    img {
      padding: 0.8rem 1rem;
    }
  }
  .getInTouch .titleContact {
    .coloredText {
      font-size: 1.7rem;
    }
    h5 {
      font-size: 1.5rem;
    }
    p {
      font-size: 1rem;
    }
  }
  .bgBlack .imageRight {
    display: none;
  }
  .productivityBody {
    .borderedLeft {
      display: none;
    }
    .content {
      flex-direction: column;
      padding: 2rem 0 5rem;
      align-items: flex-start;
      .left {
        width: 100%;
      }
      .right {
        width: 50%;
        margin-top: 1.5rem;
      }
    }
  }
  .innerPage {
    .bannerMain {
      flex-direction: column-reverse;
      margin-bottom: 0;
      .banner {
        margin-top: 3rem;
      }
      .content {
        padding-right: 0;
      }
    }
  }
  .bannerCustomer {
    .imageBanner {
      height: 50vh;
    }
    h1 {
      padding: 0 2rem;
    }
    .logoList {
      position: relative;
      flex-wrap: wrap;
      a {
        width: 25%;
      }
      img {
        width: 100%;
        height: 60px;
        padding: 1rem;
      }
    }
  }
  .customerContent {
    img {
      height: 45px;
    }
    h4 {
      font-size: 1.3rem;
    }
    p {
      font-size: 1rem;
    }
  }
  // #Home > div {
  //   transform: none !important;
  // }
  .ukMainSlider {
    height: 81vh;
    .uk-slideshow-items {
      height: 100%;
    }
    video {
      // height: auto !important;
      object-fit: cover;
    }
    .ukSlideContent {
      p {
        font-size: 1.5rem;
      }
    }
    .slidingPanel .content h4 {
      font-size: 1rem;
    }
  }
  footer {
    h4 {
      text-align: center;
    }
    .footerLinks {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      li {
        padding: 0 0.7rem;
      }
    }
    .globalSupport {
      margin: auto;
      display: block;
      margin-bottom: 1.5rem;
    }
    .footerSocial {
      justify-content: center;
    }
    .copyright {
      text-align: center;
    }
  }
  .imgBlock {
    margin-bottom: 3rem;
  }
  .subTitleBlock {
    .emptyBlock {
      height: 10vh;
    }
  }
  footer .footerMailId {
    font-size: 3rem;
  }
  .video-slider {
    .slick-prev {
      left: 50vw;
      transform: translateX(-200%);
      right: auto;
    }
    .slick-next {
      right: 50vw;
      left: auto;
      transform: translateX(200%);
    }
    .videoContent {
      flex-direction: column;
      font-size: 1.3rem;
      font-weight: 600;
    }
    .videoTitle {
      padding-left: 0;
      p {
        font-size: 1.3rem;
      }
      h3 {
        font-size: 2rem;
      }
    }
  }
  .bgBlue,
  .bgBlack {
    min-height: unset;
  }
  .aiVideo {
    background-image: none;
  }
  .slickKeyBenefits {
    margin: 0 4rem;
    .slick-prev {
      left: -57px;
    }
    .slick-next {
      right: -77px;
    }
  }
  #Home {
    > .is-inview {
      transform: none !important;
    }
  }
  .slickInsights {
    .slick-slide > div {
      padding: 5px 1rem;
    }
    .slick-prev,
    .slick-next {
      z-index: 1;
    }
    .slick-prev {
      left: 19px;
    }
    .slick-next {
      right: 19px;
    }
  }
  .innerBanner {
    height: 81vh;
    .content .mainTitle {
      width: 100%;
      padding: 2rem;
    }
  }
  .bgBlack,
  .bgBlue {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .accordionPanel {
    .MuiAccordionDetails-root img {
      display: block;
      margin-top: 1rem;
    }
  }
  .image-container {
    display: none;
  }
}
@media (min-width: 767px) {
  .cloudCapabilities .platformCloud h4 {
    background-size: 0;
  }
}
@media (max-width: 767px) {
  body {
    font-size: 0.9rem;
  }
  .ukMainSlider {
    .ukSlideContent {
      padding-bottom: 2.5rem;
      h2 {
        font-size: 1.7rem;
        margin-bottom: 0.5rem;
      }
      p {
        font-size: 1.3rem;
        margin-bottom: 0.5rem;
      }
    }
    .bgEvent {
      font-size: 0.9rem;
    }
  }
  .mainTitle {
    h2,
    h3 {
      font-size: 1.5rem;
    }
    p {
      font-size: 1rem;
    }
  }
  div.MuiAccordion-root .MuiAccordionSummary-content {
    font-size: 1.1rem;
  }
  .image-container {
    display: none;
  }
  .subTitleBlock {
    & + .subTitleBlock > div {
      margin-top: unset;
    }
    > div {
      margin-bottom: 5rem;
    }
  }
  .slickKeyBenefits {
    text-align: center;
    margin: 0 2.5rem;
    .title {
      flex-direction: column;
      h3 {
        font-size: 1.7rem;
      }
      p {
        font-size: 3rem;
      }
    }
    .slick-prev,
    .slick-next {
      width: 40px;
      height: 40px;
      svg {
        width: 29px;
        height: 29px;
      }
    }
    .slick-prev {
      left: -41px;
    }
    .slick-next {
      right: -41px;
    }
  }
  .slickInsights {
    .slick-slide > div {
      padding: 5px 0.5rem;
    }
    .slick-prev {
      left: 5px;
    }
    .slick-next {
      right: 5px;
    }
  }
  .getInTouch .titleContact {
    h5 {
      font-size: 1.7rem;
      margin-bottom: 1rem;
    }
    p {
      font-size: 1.1rem;
    }
    button {
      font-size: 1.1rem;
      padding: 0.7rem 2.5rem;
    }
  }
  .formDrawer .drawerClose {
    top: 1rem;
    right: 1rem;
  }
  .cloudCapabilities .platformCloud {
    margin-top: 0;
    .content {
      background-image: none;
      padding: 0;
      h4,
      p {
        color: var(--white);
      }
      h4 {
        font-size: 1.3rem;
        display: flex;
        align-items: center;
        background-size: auto 100%;
        padding-top: 5rem;
        color: var(--black);
        padding-bottom: 2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        background-repeat: no-repeat;
        img {
          // display: block;
          height: 40px;
          margin-right: 1rem;
        }
      }
      p {
        font-size: 1rem;
        font-weight: 500;
      }
      a {
        font-size: 0.9rem;
      }
    }
  }
}

@media (max-width: 600px) {
  .productivityBody {
    padding: 0 1rem;
    .content .right {
      width: 70%;
    }
  }
  .cloudCapabilities .platformCloud {
    background-image: none;
    padding: 0;
    .platform {
      width: 47%;
    }
    .graphic {
      width: 37%;
    }
  }
  header .menu_overlay ul li a {
    font-size: 1.1rem;
  }
  .bgBlack,
  .bgBlue {
    display: block;
  }
  .bannerCustomer .logoList {
    justify-content: center;
    img {
      padding: 0.7rem;
    }
    a {
      width: 33%;
    }
  }
  .ukMainSlider {
    .bgEvent {
      font-size: 0.7rem;
    }
  }
  .subTitleBlock .imgBlockScroll {
    height: auto;
  }
  .insights {
    > img {
      border-radius: 0;
    }
    .content {
      position: relative;
    }
    .title {
      flex-direction: column;
      align-items: flex-end;
      img {
        margin-right: 0;
        margin-bottom: 1rem;
      }
      h4 {
        font-size: 1rem;
      }
    }
  }
  .slickInsights .slick-slide > div {
    padding: 0;
  }
  .innerBanner .content .mainTitle {
    h3 {
      font-size: 1.1rem;
    }
    h2 {
      font-size: 1.7rem;
    }
    p {
      font-size: 0.9rem;
    }
  }
}

@media (max-width: 500px) {
  .cloudCapabilities .platformCloud {
    margin-top: 1rem;
    .content {
      margin: 1rem 0 2.5rem;
      h4 {
        font-size: 1.1rem;
        img {
          height: 35px;
        }
      }
      p {
        font-size: 0.9rem;
      }
      a {
        font-size: 3vw;
        img {
          height: 7px;
        }
      }
    }
  }
}

@media (max-width: 390px) {
  .ukMainSlider {
    .slidingPanel {
      &.active {
        .content {
          right: -4%;
        }
      }
    }
  }
}

@media (max-width: 378px) {
  .ukMainSlider {
    .slidingPanel {
      &.active {
        .content {
          right: -8%;
          h4 {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
}
