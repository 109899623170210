.card-stack-main-contaienr {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  margin: 0;
  /* background-color: #f0f0f0; */
  font-family: Arial, sans-serif;
}

.card-org-logo {
  max-width: 250px;
  object-fit: contain;
  height: auto;
}

.card-stack {
  position: relative;
  width: 90%; /* Use percentage for responsive width */
  max-width: 1000px; /* Set a maximum width */
  height: auto;
  width: 717px;
  height: 371px; /* Set height to auto for responsive adjustment */
  aspect-ratio: 3 / 4; /* Maintain aspect ratio */
  perspective: 1000px;
}

.card {
  position: absolute;
  width: 717px;
  height: 371px;
  padding: 40px 48px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem; /* Use rem for font-size */
  cursor: pointer;
  transition: all 0.5s ease;
  backface-visibility: visible;
}

.card:nth-child(1) {
  z-index: 5;
  transform: translateY(0) translateX(0) scale(1);
}
.card:nth-child(2) {
  z-index: 4;
  transform: translateY(20px) translateX(30px) scale(0.95);
}
.card:nth-child(3) {
  z-index: 3;
  transform: translateY(40px) translateX(50px) scale(0.9);
}
.card:nth-child(4) {
  z-index: 2;
  transform: translateY(60px) translateX(70px) scale(0.85);
}
.card:nth-child(5) {
  z-index: 1;
  transform: translateY(80px) translateX(90px) scale(0.8);
}

.card:nth-child(n + 6) {
  z-index: 1;
  transform: translateY(80px) translateX(90px) scale(0.8);
}

.card.animate-back {
  animation: cardMoveBack 0.7s ease-in-out;
}

.card.animate-forward {
  animation: cardMoveForward 0.7s ease-in-out;
}

@keyframes cardMoveBack {
  0% {
    transform: translateY(0) scale(1);
    z-index: 5;
  }
  50% {
    transform: translateY(-50%) scale(0.9);
    z-index: 5;
  }
  100% {
    transform: translateY(80px) translateX(80px) scale(0.8);
    z-index: 1;
  }
}
@keyframes cardMoveBackMob {
  0% {
    transform: translateY(0) scale(1);
    z-index: 5;
  }
  50% {
    transform: translateY(-50%) scale(0.9);
    z-index: 5;
  }
  100% {
    transform: translateY(40px) translateX(40px) scale(0.8);
    z-index: 1;
  }
}

@keyframes cardMoveForward {
  0% {
    transform: translateY(20px) translateX(20px) scale(0.95);
    z-index: 4;
  }
  100% {
    transform: translateY(0) translateX(0) scale(1);
    z-index: 5;
  }
}

/* Arrows */
.arrows {
  position: relative;
  left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.arrow {
  cursor: pointer;
  padding: 10px;
  font-size: 2rem; /* Use rem for font-size */
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

.arrow:hover {
  background-color: #f0f0f0;
}

@media (max-width: 899px) {
  .arrows {
    transform: rotate(-90deg);
    top: 10px;
  }
  .arrowsAction {
    transform: rotate(90deg);
  }
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .card-stack {
    width: 80%; /* Adjust width for smaller screens */
  }
  .card-stack {
    height: 360px;
    width: 571px;
  }
  .card {
    height: 517px;
    width: 571px;
  }
}

@media (max-width: 600px) {
  .card-stack {
    height: 320px;
    width: calc(100vw - 5rem) !important;
  }

  .card.animate-back {
    animation: cardMoveBackMob 0.7s ease-in-out;
  }
  .card {
    height: 411px;
    width: calc(100vw - 5rem) !important;
  }

  .card-org-logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }

  .card-org-logo {
    max-width: 100px;
  }
  .card:nth-child(1) {
    z-index: 3;
    transform: translateY(0) translateX(0) scale(1);
  }
  .card:nth-child(2) {
    z-index: 2;
    transform: translateY(20px) translateX(30px) scale(0.95);
  }
  .card:nth-child(3) {
    z-index: 1;
    transform: translateY(40px) translateX(50px) scale(0.9);
  }
  .card:nth-child(4) {
    z-index: 1;
    transform: translateY(40px) translateX(50px) scale(0.9);
  }
  .card:nth-child(5) {
    z-index: 1;
    transform: translateY(40px) translateX(50px) scale(0.9);
  }

  .card:nth-child(n + 6) {
    z-index: 1;
    transform: translateY(40px) translateX(50px) scale(0.9);
  }
}

@media (max-width: 480px) {
  .card {
    height: 300px;
    font-size: 1.5rem; /* Smaller font size for mobile */
  }
  .arrows {
    position: static;
  }
}
